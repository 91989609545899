import React from "react";
import Icon from "@ant-design/icons";

const ConfirmedSVG = () => (
  <svg
    id="confirmed"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <rect
      id="Rectangle_521"
      data-name="Rectangle 521"
      width="32"
      height="32"
      fill="#6d6e71"
      opacity="0"
    />
    <g
      id="Group_5215"
      data-name="Group 5215"
      transform="translate(-285.983 482.017)"
    >
      <circle
        id="Ellipse_117"
        data-name="Ellipse 117"
        cx="10"
        cy="10"
        r="10"
        transform="translate(291.983 -476.017)"
        fill="none"
        stroke="#fff"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_1077"
        data-name="Path 1077"
        d="M297.315-466.016l3.332,3.332,6.5-6.5"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const ConfirmedIcon = (props: any) => (
  <Icon component={ConfirmedSVG} {...props} />
);

export default ConfirmedIcon;
